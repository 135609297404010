import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormCustomer from '@/Models/formCustomer'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormCustomerStep3 extends Vue {
  step3: FormCustomer = new FormCustomer()
  @Prop({ required: false }) objStep3!: any
  mounted() {
    if (this.objStep3) {
      this.step3 = this.objStep3
    }
  }
  async validateFields() {
    const isValid = await (this.$refs['observer'] as any).validate()
    if (isValid) {
      return true
    } else {
      return false
    }
  }
}
