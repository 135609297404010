var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"First name","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{ref:"First name",attrs:{"type":"text"},model:{value:(_vm.step1.first_name),callback:function ($$v) {_vm.$set(_vm.step1, "first_name", $$v)},expression:"step1.first_name"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Middle name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":"This field is optional"}},[_c('b-input',{ref:"Middle name",attrs:{"type":"text"},model:{value:(_vm.step1.middle_name),callback:function ($$v) {_vm.$set(_vm.step1, "middle_name", $$v)},expression:"step1.middle_name"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Surname","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{ref:"Surname",attrs:{"type":"text"},model:{value:(_vm.step1.surname),callback:function ($$v) {_vm.$set(_vm.step1, "surname", $$v)},expression:"step1.surname"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Maiden name","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{ref:"Maiden name",attrs:{"type":"text"},model:{value:(_vm.step1.maiden_name),callback:function ($$v) {_vm.$set(_vm.step1, "maiden_name", $$v)},expression:"step1.maiden_name"}})],1)]}}])})],1),_c('div',{class:_vm.step1.marital_status == 'Divorced'
              ? 'column is-4 is-hidden'
              : 'column is-4'},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Marital Status","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-radio',{attrs:{"native-value":"Married"},model:{value:(_vm.step1.marital_status),callback:function ($$v) {_vm.$set(_vm.step1, "marital_status", $$v)},expression:"step1.marital_status"}},[_vm._v(" Married ")]),_c('b-radio',{attrs:{"native-value":"Divorced"},model:{value:(_vm.step1.marital_status),callback:function ($$v) {_vm.$set(_vm.step1, "marital_status", $$v)},expression:"step1.marital_status"}},[_vm._v(" Divorced ")])],1)]}}])})],1),(_vm.step1.marital_status == 'Divorced')?_c('div',{staticClass:"column is-4"},[_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"How many times divorced"}},[_c('i',{staticClass:"arrow-left-thin-circle-outline"}),_c('b-input',{ref:"Maiden name",attrs:{"type":"number"},model:{value:(_vm.step1.many_times_divorced),callback:function ($$v) {_vm.$set(_vm.step1, "many_times_divorced", $$v)},expression:"step1.many_times_divorced"}})],1)],1):_vm._e(),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Passport Number","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.step1.passport_number),callback:function ($$v) {_vm.$set(_vm.step1, "passport_number", $$v)},expression:"step1.passport_number"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Date of issuace of passport","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"dd/mm/yy","icon":"calendar-today"},model:{value:(_vm.step1.date_issuance_passport),callback:function ($$v) {_vm.$set(_vm.step1, "date_issuance_passport", $$v)},expression:"step1.date_issuance_passport"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Date of expiry of passport","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"dd/mm/yy","icon":"calendar-today"},model:{value:(_vm.step1.date_expiry_passport),callback:function ($$v) {_vm.$set(_vm.step1, "date_expiry_passport", $$v)},expression:"step1.date_expiry_passport"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Country of origin","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":"Select value","expanded":""},on:{"input":_vm.countrySelect},model:{value:(_vm.step1.country_origin),callback:function ($$v) {_vm.$set(_vm.step1, "country_origin", $$v)},expression:"step1.country_origin"}},[_c('option',{attrs:{"value":"USA"}},[_vm._v("USA")])])],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Phone Number","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.step1.phone_number),callback:function ($$v) {_vm.$set(_vm.step1, "phone_number", $$v)},expression:"step1.phone_number"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Email","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.step1.email),callback:function ($$v) {_vm.$set(_vm.step1, "email", $$v)},expression:"step1.email"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text has-text-left",attrs:{"label":"Address in Costa Rica","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.step1.address_cr),callback:function ($$v) {_vm.$set(_vm.step1, "address_cr", $$v)},expression:"step1.address_cr"}})],1)]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }